import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db, functions } from '../firebase';
import { collection, doc, getDocs, getDoc, query, where, writeBatch } from "firebase/firestore";
import { Context } from "../Context";
import withAuthentication from "../withAuthentication";
import { Box, TextField, Modal, FormControl, InputLabel, Select, MenuItem, } from "@mui/material"
import Loading from "../components/Loading";
import { httpsCallable } from 'firebase/functions';
import { Tooltip } from 'react-tooltip'
import { nanoid } from "nanoid";
import Papa from 'papaparse';


function Admin() {
    const { userState, loading, updateData } = useContext(Context);
    const { tenantId, role, uid, adminQuestions } = userState;

    // console.log(userState, 'userState')
    const navigate = useNavigate();
    const createAppUser2 = httpsCallable(functions, 'createAppUser2');
    const updateAppUser2 = httpsCallable(functions, 'updateAppUser2');
    const deleteAppUser2 = httpsCallable(functions, 'deleteAppUser2');
    // const sendQuestion = httpsCallable(functions, 'sendQuestion');

    // users
    const [users, setUsers] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState({ firstNameError: '', lastNameError: '', emailError: '', roleError: '' });
    const [newContact, setNewContact] = useState({ firstName: '', lastName: '', email: '', role: '' });
    const [selectedUser, setSelectedUser] = useState({ uid: '', firstName: '', lastName: '', email: '', role: '' });
    const [searchQuery, setSearchQuery] = useState('');

    // business questions
    const [selectedTeamMember, setSelectedTeamMember] = useState([]);
    const [originalSelectedTeamMember, setOriginalSelectedTeamMember] = useState([]); // used to compare if team members have been deselected in the send question modal
    const [newQuestion, setNewQuestion] = useState({ question: '', sentTo: [], dateCreated: '', dateSent: '', id: '' });
    const [selectedQuestion, setSelectedQuestion] = useState({ question: '', sentTo: [], dateCreated: '', dateSent: '', id: '' });
    const [errorQuestion, setErrorQuestion] = useState({ questionError: '', teamMemberError: '' });
    const [searchQuestions, setSearchQuestions] = useState('');

    // contacts overview
    const [contacts, setContacts] = useState([]);
    const [searchContacts, setSearchContacts] = useState('');
    const [allUserQuestions, setAllUserQuestions] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);

    // console.log(selectedUser, 'selectedUser')
    // console.log(newContact, 'newContact')
    // console.log(selectedQuestion, 'selectedQuestion')
    // console.log(newQuestion, 'newQuestion')
    // console.log(adminQuestions, 'adminQuestions')
    // console.log(questionsFromAdmin, 'questionsFromAdmin')
    // console.log(userState, 'userState')
    // console.log(allUserQuestions, 'allUserQuestions')
    // console.log(selectedContact, 'selectedContact')

    // modal styles
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: '75vh',
        bgcolor: 'background.paper',
        border: 'none',
        BorderRadius: '16px',
        boxShadow: 24,
        p: 8,
    };
    const modalStyleWide = {
        ...modalStyle,
        width: '850px',
        height: '85vh',
    }
    const modalStyleWideContacts = {
        ...modalStyle,
        width: '50vw',
        height: '95%',
        p: 4,
    }
    const questionOneOptions = [
        { value: '20', label: '(E) Empathy' },
        { value: '15', label: '(D) Dunbar' },
        { value: '10', label: '(M) Memory' },
        { value: '5', label: '(P) Plato' },
    ]
    const questionTwoOptions = [
        { value: '20', label: '(A) Integration' },
        { value: '15', label: '(B) Pulling' },
        { value: '10', label: '(C) Observation' },
        { value: '5', label: '(D) Pushed Data' },
    ]
    const questionThreeOptions = [
        { value: '5', label: 'Up to 5 years' },
        { value: '20', label: 'Up to 10 years' },
        { value: '60', label: 'Up to 15 years' },
        { value: '90', label: '15 or more' },
    ]
    const questionFourOptions = [
        { value: '30', label: 'Active' },
        { value: '10', label: 'Pending' },
        { value: '5', label: 'Non-Active' },
    ]
    const questionFiveOptions = [
        { value: '50', label: 'TIKO' },
        { value: '100', label: 'TINO' },
        { value: '0', label: 'Not relevant' },
    ]

    const getOptionLabel = (value, options) => {
        const option = options.find(option => option.value === value);
        return option ? option.label : '';
    }

    // check if user is admin and if not redirect to home page
    useEffect(() => {
        if (loading) return;
        if (!role || role !== 'admin') {
            navigate('/');
        } else {
            // resetIdleTimer(); // Reset the idle timer on user activity
        }
    }, [role, loading, navigate]);

    // fetch all users
    useEffect(() => {
        if (loading) return;
        const fetchAllUsers = async () => {
            setMessage(''); // Clear any previous error messages

            try {
                const usersRef = collection(db, 'tenants', tenantId, 'users');
                const userSnapshot = await getDocs(usersRef);
                const users = userSnapshot.docs.map(doc => {
                    const userData = doc.data();
                    return {
                        uid: doc.id,
                        ...userData
                    };
                });
                setUsers(users);

                // get contacts
                const contacts = users && users.map((user) => {
                    return {
                        userName: `${user.firstName} ${user.lastName}` || '',
                        contacts: user.contacts || [],
                    }

                });
                setContacts(contacts);

                // get all user questions
                const questionsArray = [];
                users.map((user) => {
                    user.questionsFromAdmin?.forEach((q) => {
                        let question = {
                            id: q.id,
                            question: q.question
                        };
                        questionsArray.push(question);
                    });

                    user.projects?.forEach((q) => {
                        let question = {
                            id: q.id,
                            question: q.step1?.FinalQ
                        };
                        questionsArray.push(question);
                    });

                    return null;
                });

                setAllUserQuestions(questionsArray);

            } catch (err) {
                console.log(err);
                setMessage(err.message);
            }
        };
        fetchAllUsers();
    }, [loading, forceUpdate, tenantId]);

    // MODALS //
    // 1. users modal
    const [open, setOpen] = useState(false); // user modal state
    const [mode, setMode] = useState('create'); // Initialize with 'create' mode
    const handleUserModalClose = () => setOpen(false); // close user modal

    // 2. questions modal
    const [openQuestionModal, setOpenQuestionModal] = useState(false); // question modal state
    const [openSendQuestionModal, setOpenSendQuestionModal] = useState(false); // send question modal state
    const handleQuestionModalClose = () => setOpenQuestionModal(false);  // close question modal
    const handleSendQuestionModalClose = () => setOpenSendQuestionModal(false);  // close send question modal

    // 3. contacts modal
    const [openContactsModal, setOpenContactsModal] = useState(false); // contacts modal state

    // users functions
    const openCreateModal = () => {
        setMode('create');
        setSelectedUser(null); // Clear the selected user when opening in create mode
        setOpen(true);
        setMessage('');
    };

    const openUpdateUserModal = (user) => {
        setMode('update');
        setSelectedUser(user); // Set the selected user when opening in update mode
        setOpen(true);
        setMessage('');
    };

    const addSingleUser = async (e) => {
        e.preventDefault();
        // check if all fields are filled
        if (!newContact.firstName) {
            setError({ firstNameError: 'Please enter a first name' });
            return;
        }
        if (!newContact.lastName) {
            setError({ lastNameError: 'Please enter a last name' });
            return;
        }
        if (!newContact.email) {
            setError({ emailError: 'Please enter an email' });
            return;
        }
        if (!newContact.role) {
            setError({ roleError: 'Please enter a role' });
            return;
        }
        // if selected role is admin, make sure this is what the user wants:
        if (newContact.role === 'admin' || newContact.role === 'manager') {
            if (newContact.role === 'admin') {
                const confirmAdmin = window.confirm(`Are you sure you want to give this user an admin role? This will give them access to the admin panel and the ability to manage users.`);
                if (!confirmAdmin) return;
            }
            if (newContact.role === 'manager') {
                const confirmManager = window.confirm(`Are you sure you want to give this user a manager role? This will give them access to the reporting tool where they can see all employees data.`);
                if (!confirmManager) return;
            }
        }

        // if there's an email, check if it's typed correctly
        if (newContact.email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailRegex.test(newContact.email)) {
                setError({ emailError: 'Please enter a valid email' });
                return;
            }
        }
        setError({ firstNameError: '', lastNameError: '', emailError: '', roleError: '' });

        const usersRef = collection(db, 'tenants', tenantId, 'users');
        const userQuery = query(usersRef, where("email", "==", newContact.email));

        try {
            const querySnapshot = await getDocs(userQuery);
            // Check if any documents match the query
            if (!querySnapshot.empty) {
                setError({ emailError: 'User already exists' });
                setMessage('User with this email already exists');
                return;
            }
        } catch (err) {
            console.log(err);
            setMessage(err.message);
        }
        // check if user has a tenantId by checking custom claims
        const user = auth.currentUser;
        try {
            const token = await user.getIdTokenResult();
            const claims = token.claims;
            if (!claims.tenantId) {
                setMessage('You do not have the right credentials. Please contact your administrator.')
                return;
            }
        } catch (err) {
            console.log(err)
        }

        // change button to loading:
        setMessage('Adding user...');
        const button = document.querySelector('.addUserBtn');
        // button.innerHTML = '<i className="fa fa-spinner fa-spin"></i>';
        button.disabled = true;

        // add user to db
        try {
            await createAppUser2({
                email: newContact.email,
                firstName: newContact.firstName,
                lastName: newContact.lastName,
                role: newContact.role
            });
            // console.log('newUser', newUser)
            // Send password reset email
            // const { message, user } = newUser.data;
            // const newEmail = user.email;
        } catch (err) {
            console.log(err);
            setMessage(err.message);
        } finally {
            // button.innerHTML = 'Add User';
            button.disabled = false;
            setTimeout(() => {
                setMessage('');
            }, 4000);
            setForceUpdate((prev) => !prev);
            handleUserModalClose();
        }
    }

    async function updateUser(e) {
        e.preventDefault();
        // check if all fields are filled
        if (!selectedUser.firstName) {
            setError({ firstNameError: 'Please enter a first name' });
            return;
        }
        if (!selectedUser.lastName) {
            setError({ lastNameError: 'Please enter a last name' });
            return;
        }
        if (!selectedUser.email) {
            setError({ emailError: 'Please enter an email' });
            return;
        }
        if (!selectedUser.role) {
            setError({ roleError: 'Please enter a role' });
            return;
        }

        if (selectedUser.email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailRegex.test(selectedUser.email)) {
                setError({ emailError: 'Please enter a valid email' });
                return;
            }
        }
        setError({ firstNameError: '', lastNameError: '', emailError: '', roleError: '' });

        setMessage('Updating user...');
        const button = document.querySelector('.updateUserBtn');
        // button.innerHTML = '<i className="fa fa-spinner fa-spin"></i>';
        button.disabled = true;
        try {
            // Update user in Firebase Authentication
            await updateAppUser2({
                uid: selectedUser.uid,
                email: selectedUser.email,
                firstName: selectedUser.firstName,
                lastName: selectedUser.lastName,
                role: selectedUser.role
            });

        } catch (error) {
            console.error("Error updating user:", error);
            setMessage(`Error: cannot update user ${selectedUser.firstName + selectedUser.lastName}:` + error.message);

        } finally {
            setMessage('User updated successfully');
            // button.innerHTML = 'Update User';
            button.disabled = false;
            setTimeout(() => {
                setMessage('');
            }, 2000);
            setForceUpdate((prev) => !prev);
            handleUserModalClose();
        }
    };

    const handleFileUpload = (event) => {
        setMessage('')
        // if nothing is selected, return:
        if (!event.target.files || event.target.files.length === 0) return;
        const file = event.target.files[0];

        // Check if the file is a CSV
        if (file && file.type === 'text/csv') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const csvString = e.target.result;
                createUsersFromCSV(csvString);
            };
            reader.readAsText(file);
        } else {
            console.log('file type', file.type)
            alert('Please upload a .CSV file');
        }
    };

    const createUsersFromCSV = async (data) => {
        const button = document.querySelector('.addMultipleBtn');
        button.disabled = true;
        try {
            setMessage('Uploading...')
            const { data: parsedData, errors } = Papa.parse(data, {
                header: true,
                skipEmptyLines: true,
                dynamicTyping: true,
                trimHeaders: true,
                trim: true,
            });
            const skippedItems = [];

            if (errors.length > 0) {
                console.error('Error parsing CSV file:', errors);
                alert('Error uploading the CSV file');
                setMessage('')
                return;
            }

            // Check if the required headers exist
            const requiredHeaders = ['firstName', 'lastName', 'email', 'role'];
            const csvHeaders = Object.keys(parsedData[0]);
            const missingHeaders = requiredHeaders.filter(header => !csvHeaders.includes(header));

            if (missingHeaders.length > 0) {
                setMessage('')
                console.error('Missing required headers:', missingHeaders);
                alert(`Missing required headers in the CSV file: ${missingHeaders.join(', ')}, please check the file and try again`);
                return;
            }
            for (const item of parsedData) {
                if (!item.email || !item.firstName || !item.lastName) {
                    console.warn('Skipping invalid line:', item);
                    skippedItems.push(item);
                    continue; // Skip this iteration and move to the next line
                }
                const newUser = await createAppUser2({
                    email: item.email,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    role: item.role,
                });

                if (newUser.error) {
                    console.error('Error creating user:', newUser.error);
                    continue; // Skip further processing for this user
                }

                setMessage('Users uploaded successfully');
                alert('Users uploaded successfully');
            }
            if (skippedItems.length > 0) {
                const skippedItemsList = skippedItems.map((item) => {
                    return `- Email: ${item.email}, First Name: ${item.firstName}, Last Name: ${item.lastName}`;
                }).join('\n');
                alert(`Skipped ${skippedItems.length} invalid line(s) in CSV file. Please check the following items:\n\n${skippedItemsList}`);
            }
            setForceUpdate((prev) => !prev); // Trigger re-render to fetch latest user data
        } catch (err) {
            console.log(err);
            setMessage(err.message);
            alert(err.message);

        } finally {
            setTimeout(() => {
                setMessage('');
            }, 4000);
            button.disabled = false;
        }
    };

    const deleteUserFromAuthAndDB = async (userId, userFirstName, userLastName) => {
        // warn user before deleting
        const confirmDelete = window.confirm(`Are you sure you want to delete ${userFirstName + userLastName}? This action cannot be undone.`);
        if (!confirmDelete) return;
        setMessage('Deleting user. Please wait...');

        try {
            // Delete user from Firebase Authentication
            await deleteAppUser2({ uid: userId });
            setMessage(`User ${userFirstName + userLastName} deleted successfully.`);
            setForceUpdate((prev) => !prev);
        } catch (error) {
            setMessage(`Error: cannot delete user ${userFirstName + userLastName}:` + error.message);
            alert(`Error: cannot delete user ${userFirstName + userLastName}:` + error.message);
        } finally {
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    };

    const filteredUsers = users && users.filter((user) => {
        if (!searchQuery) {
            return true; // Show all users if no search query is entered
        }
        const searchTermLowerCase = searchQuery.toLowerCase();

        return (
            (user.firstName && user.firstName.toLowerCase().includes(searchTermLowerCase)) ||
            (user.lastName && user.lastName.toLowerCase().includes(searchTermLowerCase)) ||
            (user.email && user.email.toLowerCase().includes(searchTermLowerCase)) ||
            (user.role && user.role.toLowerCase().includes(searchTermLowerCase))
        );
    });

    // both search fields use the same function
    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchQuery')
            setSearchQuery(value);
        else if (name === 'searchQuestions')
            setSearchQuestions(value);
        else if (name === 'searchContacts')
            setSearchContacts(value);
    };

    // Questions functions

    // filter questions
    const filteredQuestions = adminQuestions && adminQuestions.filter((question) => {
        if (!searchQuestions) {
            return true; // Show all questions if no search query is entered
        }
        const searchTermLowerCase = searchQuestions.toLowerCase();

        return (
            (question.question && question.question.toLowerCase().includes(searchTermLowerCase)) ||
            (question.dateCreated && question.dateCreated.toLowerCase().includes(searchTermLowerCase)) ||
            (question.sentTo && question.sentTo.join(', ').toLowerCase().includes(searchTermLowerCase))
        );
    });

    const openCreateQuestionModal = () => {
        setMode('create');
        setSelectedQuestion(''); // Clear the selected question when opening in create mode
        setOpenQuestionModal(true);
    }

    const openUpdateQuestionModal = (question) => {
        setMode('update');
        setSelectedQuestion(question);
        setOpenQuestionModal(true);
    }

    const handleSendQuestionModalOpen = (question) => {
        const uids = getUIDsFromNames(question.sentTo, filteredUsers);
        setSelectedTeamMember(uids);
        setOriginalSelectedTeamMember(uids);
        setSelectedQuestion(question);
        setOpenSendQuestionModal(true);
    }

    const addQuestion = async (e) => {
        e.preventDefault();
        // check if all fields are filled
        if (!newQuestion.question) {
            setErrorQuestion({ questionError: 'Please enter a question' });
            return;
        }
        setErrorQuestion({ questionError: '' });

        // construct the new question object:
        const questionObject = {
            question: newQuestion.question,
            sentTo: [],
            dateCreated: new Date().toLocaleDateString(),
            dateSent: new Date().toLocaleDateString(),
            id: nanoid(10),
        }
        // 1. save the new question to the admin question array from state (adminQuestions)
        try {
            const updatedAdminQuestions = [...adminQuestions, questionObject];
            setMessage('saving question...')
            await updateData('adminQuestions', updatedAdminQuestions);
            setMessage('Question saved successfully');
            // close the modal
            handleQuestionModalClose();
            setNewQuestion({ question: '', sentTo: [], dateCreated: '', dateSent: '', id: '' });
            setForceUpdate((prev) => !prev);
        }
        catch (err) {
            console.log(err);
            setMessage(err.message);
        }
    }

    const updateQuestion = async (e) => {
        e.preventDefault();
        // check if all fields are filled
        if (!selectedQuestion.question) {
            setErrorQuestion({ questionError: 'Please enter a question' });
            return;
        }
        setErrorQuestion({ questionError: '' });

        // 1. save the updated question to the admin question array from state (adminQuestions)
        try {
            const updatedAdminQuestions = adminQuestions.map((q) => {
                if (q.id === selectedQuestion.id) {
                    return {
                        ...q,
                        question: selectedQuestion.question
                    }
                }
                return q;
            });
            setMessage('updating question...')
            await updateData('adminQuestions', updatedAdminQuestions);
            setMessage('Question updated successfully');
            // close the modal
            handleQuestionModalClose();
            setForceUpdate((prev) => !prev);
        }
        catch (err) {
            console.log(err);
            setMessage(err.message);
        }
    }

    const getUIDsFromNames = (names) => {
        if (!names || names.length === 0) return [];
        return names.map(name => {
            const user = filteredUsers.find(user => `${user.firstName} ${user.lastName}` === name);
            return user ? user.uid : null;
        }).filter(uid => uid !== null);
    };

    const handleSendingQuestionChange = (uid) => {
        setSelectedTeamMember((prev) => {
            if (prev.includes(uid)) {
                return prev.filter((id) => id !== uid);
            } else {
                return [...prev, uid];
            }
        }
        );
    }

    const sendQuestionToUser = async (e) => {
        e.preventDefault();

        // 1. check if at least one team member is selected
        if (!selectedTeamMember || selectedTeamMember.length === 0) {
            setErrorQuestion({ teamMemberError: 'Please select at least one team member' });
            return;
        }
        setErrorQuestion({ teamMemberError: '' });

        // 2. filter out the users who were deselected
        const deselectedTeamMembers = originalSelectedTeamMember.filter(uid => !selectedTeamMember.includes(uid));

        // 3. send the question to the selected team members
        try {
            const usersRef = collection(db, 'tenants', tenantId, 'users');
            const batch = writeBatch(db);

            for (const uid of selectedTeamMember) {
                const userDocRef = doc(usersRef, uid);

                if (!userDocRef) {
                    // find user name from users and alert
                    const user = users.find((user) => user.uid === uid);
                    setMessage(`User ${user.firstName + user.lastName} not found`);
                    continue;
                }
                const userDocSnapshot = await getDoc(userDocRef);
                if (!userDocSnapshot.exists()) {
                    // find user name from users and alert
                    const user = users.find((user) => user.uid === uid);
                    setMessage(`User ${user.firstName + user.lastName} not found`);
                    continue;
                }

                setMessage('sending question...')

                const userData = userDocSnapshot.data();
                const userQuestions = userData.questionsFromAdmin || [];
                const updatedUserQuestions = [...userQuestions, selectedQuestion];
                batch.update(userDocRef, { questionsFromAdmin: updatedUserQuestions });
            }
            await batch.commit();

            // 4. remove the question from the users who were deselected
            try {

                const batch = writeBatch(db);
                for (const uid of deselectedTeamMembers) {
                    const userDocRef = doc(usersRef, uid);
                    const userDocSnapshot = await getDoc(userDocRef);
                    if (!userDocSnapshot.exists()) {
                        // find user name from users and alert
                        const user = users.find((user) => user.uid === uid);
                        setMessage(`User ${user.firstName + user.lastName} not found`);
                        continue;
                    }

                    const userData = userDocSnapshot.data();
                    const userQuestions = userData.questionsFromAdmin || [];
                    const updatedUserQuestions = userQuestions.filter(q => q.id !== selectedQuestion.id);
                    batch.update(userDocRef, { questionsFromAdmin: updatedUserQuestions });
                }
                await batch.commit();
            } catch (err) {
                console.log(err);
                setMessage(err.message);
            }

            // 5. update the question in the adminQuestions array in the db, adding the names of the team members it was sent to:
            const teamMembers = users.filter((user) => selectedTeamMember.includes(user.uid));
            const teamMemberNames = teamMembers.map((member) => `${member.firstName} ${member.lastName}`);

            const updatedAdminQuestions = adminQuestions.map((q) => {
                if (q.id === selectedQuestion.id) {
                    return {
                        ...q,
                        sentTo: teamMemberNames
                    }
                }
                return q;
            }
            );

            await updateData('adminQuestions', updatedAdminQuestions);
            setForceUpdate((prev) => !prev);
            setSelectedTeamMember([]);

            // close
            handleSendQuestionModalClose();
        } catch (err) {
            console.log(err);
            setMessage(err.message);
            // close
            handleSendQuestionModalClose();
        }

    }

    const deleteQuestionFromAdminAndUser = async (questionId, question, sentTo) => {
        // warn user before deleting
        const confirmDelete = window.confirm(`Are you sure you want to delete the question? This action cannot be undone. This will also delete the question from the user's profile. You are about to delete the following question: ${question}`);
        if (!confirmDelete) return;

        setMessage('Deleting question. Please wait...');
        // 1. delete the question from the adminQuestions array in the db (for the admin)
        try {
            const updatedAdminQuestions = adminQuestions.filter((q) => q.id !== questionId);
            setMessage('deleting question...')
            await updateData('adminQuestions', updatedAdminQuestions);
            setMessage('Question deleted successfully');
            setForceUpdate((prev) => !prev);
        } catch (err) {
            console.log(err);
            setMessage(err.message);
        }

        // 2. delete the question from the user's profile in the db
        // for each user in the sentTo array, delete the question from their profile in the db

        try {
            const usersRef = collection(db, 'tenants', tenantId, 'users');
            const batch = writeBatch(db);

            for (const name of sentTo) {
                const userDocRef = query(usersRef, where("firstName", "==", name.split(' ')[0]), where("lastName", "==", name.split(' ')[1]));
                const userDocSnapshot = await getDocs(userDocRef);
                if (!userDocSnapshot.empty) {
                    const userData = userDocSnapshot.docs[0].data();
                    const userQuestions = userData.questionsFromAdmin || [];
                    const updatedUserQuestions = userQuestions.filter((q) => q.id !== questionId);
                    batch.update(userDocSnapshot.docs[0].ref, { questionsFromAdmin: updatedUserQuestions });
                }
            }
            await batch.commit();

        } catch (err) {
            console.log(err);
            setMessage(err.message);
        }
    }

    // contacts
    const getQuestionText = (analyzeArray) => {
        if (!analyzeArray) return '';
        let questionsText = []
        analyzeArray.forEach((a) => {
            const question = allUserQuestions && allUserQuestions.find((q) => q.id === a.questionId);
            return question ?
                // limit to first 20 words
                questionsText.push(question.question.split(' ').slice(0, 20).join(' ') + '...') : null;
        }
        )
        return questionsText.join(', ');

    }

    // filter contacts
    const filteredContacts = contacts && contacts.map((contact) => {
        if (!searchContacts) {
            return contact; // Return all contacts if no search query is entered
        }
        const searchTermLowerCase = searchContacts.toLowerCase();

        const userMatches = (
            (contact.userName && contact.userName.toLowerCase().includes(searchTermLowerCase))
        );

        // Filter contact.contacts based on the search term
        const matchedContacts = contact.contacts.filter(c =>
            (c.fullName && c.fullName.toLowerCase().includes(searchTermLowerCase)) ||
            (c.dateAdded && c.dateAdded.toLowerCase().includes(searchTermLowerCase)) ||
            (c.analyze && getQuestionText(c.analyze).toLowerCase().includes(searchTermLowerCase)) ||
            (c.networkType && c.networkType.toLowerCase().includes(searchTermLowerCase)) ||
            (c.keywords && c.keywords.toLowerCase().includes(searchTermLowerCase))
        );

        if (userMatches || matchedContacts.length > 0) {
            return {
                ...contact,
                contacts: userMatches ? contact.contacts : matchedContacts
            };
        }
        return null;
    }).filter(contact => contact !== null); // Remove any null values

    const openContactModal = (contact) => {
        setSelectedContact(contact);
        setOpenContactsModal(true);
    }

    const closeContactModal = () => {
        setSelectedContact('');
        setOpenContactsModal(false);
    }



    if (loading) return <Loading />

    return (
        <div className="app-home-container">
            <div className="app-title">
                <h4>Admin Panel</h4>
                <h6>Manage user accounts, send questions to your employees and go over all contacts in the system</h6>
                {message && <div className="message">{message}</div>}
            </div>
            <div className="admin-main-container">
                <div className="admin-section users">
                    <div className="element-title">
                        <h6>Manage users
                            <i className="fas fa-info-circle"
                                data-tooltip-id="csvTooltip1"
                                data-tooltip-variant="info"
                                data-tooltip-content="To add multiple users, upload a .csv file. You can download the template. DO NOT EDIT the headers of the file. Please make sure your CSV file has the exact following headers: firstName, lastName, email, role. Under 'role', please enter 'user' for regular user, 'manager' for manager and 'admin' for admin." >
                            </i>
                        </h6>
                        <Tooltip place="right" className="tooltip" id="csvTooltip1" />
                    </div>
                    <div className="element-body" style={{ width: '100%' }}>
                        <div className="admin-btns" >
                            <div >
                                <button
                                    onClick={openCreateModal}
                                    style={{ marginRight: '10px' }}
                                >Add single user
                                    <i className="fas fa-solid fa-user-plus"></i>
                                </button>

                                <label
                                    className="uploadFileBtn"
                                    style={{ marginRight: '5px', padding: '10px' }}
                                    htmlFor="fileUpload">Add users (CSV)
                                    <i className="fas fa-solid fa-upload"></i>

                                </label>

                                <input
                                    id="fileUpload"
                                    type="file"
                                    accept=".csv"
                                    className="file-input"
                                    onChange={handleFileUpload}
                                    style={{ display: 'none' }}
                                />

                                <button
                                    onClick={() => window.open('/templates/adminTemplate.csv', '_blank')}
                                    className="downloadTemplateBtn">
                                    Download CSV template
                                    {/* <a
                                        href="/templates/adminTemplate.csv"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Download CSV template
                                    </a> */}
                                    <i className="fas fa-solid fa-download"></i>
                                </button>
                            </div>
                            <div className="search-input-wrapper">
                                <input
                                    value={searchQuery || ''}
                                    onChange={handleSearchChange}
                                    placeholder="Search users "
                                    name="searchQuery"
                                    className="search-input"
                                />
                                <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                            </div>
                        </div>

                        <div className='table-container' style={{ marginTop: '10px' }} >
                            <table >
                                <thead>
                                    <tr >
                                        {/* <th>ID</th> */}
                                        <th >First Name</th>
                                        <th >Last Name</th>
                                        <th >Email</th>
                                        <th >Role</th>
                                        <th >Update User</th>
                                        <th >Delete User</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers && filteredUsers.map((user) => (
                                        <tr key={user.email || ''}>
                                            {/* <td>{user.uid}</td> */}
                                            <td>{user.firstName || ''}</td>
                                            <td>{user.lastName || ''}</td>
                                            <td>{user.email || ''}</td>
                                            <td>{user.role || ''}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button
                                                    className="transparentBtn"
                                                    onClick={() => openUpdateUserModal(user)} >
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button
                                                    className="transparentBtn"
                                                    onClick={() => deleteUserFromAuthAndDB(user.uid, user.firstName, user.lastName)}>
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="admin-section questions">
                    <div className="element-title">
                        <h6>Send questions to team members
                            <i className="fas fa-info-circle"
                                data-tooltip-id="questionsTooltip"
                                data-tooltip-variant="info"
                                data-tooltip-content="Send questions to team members. Click on the 'paper plane' icon to send or unsend a question. Click on the 'trash' icon to delete a question."> </i>
                        </h6>
                        <Tooltip place="right" className="tooltip" id="questionsTooltip" />
                    </div>
                    <div className="element-body" style={{ width: '100%' }}>
                        <div className="admin-btns questions">
                            <button
                                onClick={openCreateQuestionModal}
                                style={{ marginLeft: '0px' }}
                            >Add new question
                                <i className="fas fa-solid fa-circle-plus"></i>
                            </button>
                            <div className="search-input-wrapper">
                                <input
                                    value={searchQuestions || ''}
                                    onChange={handleSearchChange}
                                    placeholder="Search questions"
                                    name="searchQuestions"
                                    className="search-input"
                                />
                                <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                            </div>
                        </div>
                        <div className='table-container admin-table-container' style={{ marginTop: '10px' }} >
                            <table  >
                                <thead>
                                    <tr >
                                        {/* <th >Question ID</th> */}
                                        <th >Date created</th>
                                        <th >Question</th>
                                        <th >Sent to</th>
                                        <th >Update Question</th>
                                        <th >Send / Unsend Question</th>
                                        <th >Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(filteredQuestions.map((question) => (
                                        <tr key={question.id || ''}>
                                            {/* <td>{question.id || ''}</td> */}
                                            <td>{question.dateCreated || ''}</td>
                                            <td>{question.question || ''}</td>
                                            <td>{question.sentTo ? question.sentTo.join(', ') : ''}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button
                                                    className="transparentBtn"
                                                    onClick={() => openUpdateQuestionModal(question)} >
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button
                                                    className="transparentBtn"
                                                    onClick={() => handleSendQuestionModalOpen(question)} >
                                                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button
                                                    className="transparentBtn"
                                                    onClick={() => deleteQuestionFromAdminAndUser(question.id, question.question, question.sentTo)}>
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className="admin-section contacts">
                    <div className="element-title">
                        <h6>Contacts overview
                            <i className="fas fa-info-circle"
                                data-tooltip-id="contactsTooltip"
                                data-tooltip-variant="info"
                                data-tooltip-content="View and analyze contacts added by team members. Click on the 'eye' icon to view the full profile analysis." >
                            </i>
                        </h6>
                        <Tooltip place="right" className="tooltip" id="contactsTooltip" />
                    </div>
                    <div className="element-body" style={{ width: '100%' }}>

                        <div className="admin-btns">
                            <div className="search-input-wrapper">
                                <input
                                    value={searchContacts || ''}
                                    onChange={handleSearchChange}
                                    placeholder="Search contacts"
                                    variant="outlined"
                                    size="small"
                                    name="searchContacts"
                                    className="search-input"
                                />
                                <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                            </div>
                        </div>
                        <div className='table-container contacts-overview-table-container' style={{ marginTop: '10px' }} >
                            <table >
                                <thead>
                                    <tr >
                                        <th >Name</th>
                                        <th style={{ width: '100px' }}>Date added</th>
                                        <th >Added by</th>
                                        <th >Questions analyzed (first 20 words)</th>
                                        <th >Keywords</th>
                                        <th style={{ width: '170px' }}>External/Internal TIKO </th>
                                        <th style={{ width: '100px' }} >Analysis
                                            <i className="fa fa-info-circle"
                                                data-tooltip-id="profileAnalysisTooltip"
                                                data-tooltip-variant="info"
                                                data-tooltip-content="Analysis are done in step 3 of the Contacts tool"> </i>
                                        </th>
                                        <Tooltip place="right" className="tooltip" id="profileAnalysisTooltip" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredContacts && filteredContacts.map((contact) => (
                                        contact.contacts && contact.contacts.map((c) => (
                                            <tr style={{ textAlign: 'center' }}
                                                key={c.id || ''}>
                                                <td>{c.fullName || ''}</td>
                                                <td>{c.dateAdded || ''}</td>
                                                <td>{contact.userName || ''}</td>
                                                <td style={{ textAlign: 'left' }}>
                                                    {getQuestionText(c.analyze) || ''}
                                                </td>
                                                <td style={{ textAlign: 'left' }}>
                                                    {c.keywords ? c.keywords : ''}
                                                </td>
                                                <td>{c.networkType ? c.networkType === 'internal' ?
                                                    'Internal' : 'External'
                                                    : 'Not specified'
                                                }</td>
                                                <td>
                                                    {(c.analyze && c.analyze?.length > 0 && c.analyze[0].answers) ?
                                                        <button
                                                            className="transparentBtn eye-icon"
                                                            onClick={() => openContactModal(c)}
                                                        >
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                        </button>
                                                        :
                                                        'No analysis'

                                                    }

                                                </td>
                                            </tr>
                                        )))
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                {/* users modal */}
                <div className="admin-modal-container">
                    <Modal
                        open={open}
                        onClose={handleUserModalClose}
                        aria-labelledby="create-user-modal"
                        aria-describedby="create-user-modal"
                    >
                        <Box sx={modalStyle}>
                            {mode === 'create' && (
                                <div>
                                    <h4>Add new user</h4>
                                    <p>Please fill in the following fields:</p>
                                </div>
                            )}
                            {mode === 'update' && selectedUser && (
                                <div>
                                    <h4>Update user: {selectedUser.firstName} {selectedUser.lastName}</h4>
                                    <p>Please update the following fields:</p>
                                </div>
                            )}
                            <form style={{ margin: '20px 0px' }} onSubmit={mode === 'create' ? addSingleUser : updateUser}>
                                <TextField
                                    label="First Name"
                                    variant="outlined"
                                    sx={{ width: '100%', mt: 2 }}
                                    value={mode === 'create' ? newContact.firstName || '' : selectedUser.firstName || ''}
                                    onChange={(e) => {
                                        if (mode === 'create') {
                                            setNewContact({ ...newContact, firstName: e.target.value });
                                            setError({ firstNameError: '' });
                                        } else {
                                            setSelectedUser({ ...selectedUser, firstName: e.target.value });
                                            setError({ ...error, firstNameError: '' });
                                        }
                                    }}
                                    error={Boolean(error.firstNameError)}
                                    helperText={error.firstNameError}
                                />
                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    sx={{ width: '100%', mt: 2 }}
                                    value={mode === 'create' ? newContact.lastName || '' : selectedUser.lastName || ''}
                                    onChange={(e) => {
                                        if (mode === 'create') {
                                            setNewContact({ ...newContact, lastName: e.target.value });
                                            setError({ lastNameError: '' });
                                        } else {
                                            setSelectedUser({ ...selectedUser, lastName: e.target.value });
                                            setError({ ...error, lastNameError: '' });
                                        }
                                    }}
                                    error={Boolean(error.lastNameError)}
                                    helperText={error.lastNameError}
                                />
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    sx={{ width: '100%', mt: 2 }}
                                    value={mode === 'create' ? newContact.email || '' : selectedUser.email || ''}
                                    onChange={(e) => {
                                        if (mode === 'create') {
                                            setNewContact({ ...newContact, email: e.target.value });
                                            setError({ emailError: '' });
                                        } else {
                                            setSelectedUser({ ...selectedUser, email: e.target.value });
                                            setError({ ...error, emailError: '' });
                                        }
                                    }}
                                    error={Boolean(error.emailError)}
                                    helperText={error.emailError}
                                />
                                <FormControl sx={{ width: '100%', mt: 2, textAlign: 'left' }}>
                                    <InputLabel id="role">User Role</InputLabel>
                                    <Select
                                        value={mode === 'create' ? newContact.role || '' : selectedUser.role || ''}
                                        labelId="role"
                                        id="role"
                                        label="User Role"
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, role: e.target.value });
                                                setError({ roleError: '' });
                                            } else {
                                                setSelectedUser({ ...selectedUser, role: e.target.value });
                                                setError({ ...error, roleError: '' });
                                            }
                                        }}
                                        error={Boolean(error.roleError)}
                                    >
                                        <MenuItem value="user">User</MenuItem>
                                        <MenuItem value="manager">Manager</MenuItem>
                                        <MenuItem value="admin">Admin</MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{ marginTop: '30px' }}>
                                    <div className="successMessage">
                                        <p>{message}</p>
                                    </div>
                                    {mode === 'create' ? (
                                        <button style={{ width: '150px' }} className="addUserBtn" type="submit">Add User</button>
                                    ) : (
                                        <button style={{ width: '150px' }} className='updateUserBtn' type="submit">Update User</button>
                                    )}
                                    <button style={{ width: '150px' }} className="greyButton" type="button" onClick={handleUserModalClose}>
                                        Cancel
                                    </button>
                                </div>

                            </form>
                        </Box>
                    </Modal>
                </div>
                {/* questions modal */}
                <div className="admin-modal-container questions">
                    <Modal
                        open={openQuestionModal}
                        onClose={handleQuestionModalClose}
                        aria-labelledby="create-question-modal"
                        aria-describedby="create-question-modal"
                    >
                        <Box sx={modalStyleWide}>
                            {mode === 'create' && (
                                <div>
                                    <h4>Write a new business question</h4>
                                    <p>Write the question below. Later you can send it to your team members.</p>
                                </div>
                            )}
                            {mode === 'update' && selectedQuestion && (
                                <div>
                                    <h4>Update business question</h4>
                                    <p>Update the question below. Later you can send it to your team members.</p>
                                </div>
                            )}
                            <form onSubmit={mode === 'create' ? addQuestion : updateQuestion}>
                                <FormControl sx={{ width: '100%', mt: 2, textAlign: 'left' }}>
                                    <TextField
                                        label="Question"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={8}
                                        placeholder='Example: "what is the best marketing channel?"'
                                        sx={{ width: '100%', mt: 2 }}
                                        value={mode === 'create' ? (newQuestion.question || '') : (selectedQuestion.question || '')}
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewQuestion({ ...newQuestion, question: e.target.value });
                                                setError({ questionError: '' });
                                            } else {
                                                setSelectedQuestion({ ...selectedQuestion, question: e.target.value });
                                                setError({ ...error, questionError: '' });
                                            }
                                        }}
                                        error={Boolean(error.questionError)}
                                        helperText={error.questionError}
                                    />
                                </FormControl>
                                <div style={{ marginTop: '20px' }}>
                                    {mode === 'create' ? (
                                        <button style={{ minWidth: '150px' }} className="addUserBtn" type="submit">Add Question</button>
                                    ) : (
                                        <button style={{ minWidth: '150px' }} className='updateUserBtn' type="submit">Update Question</button>
                                    )}
                                    <button style={{ minWidth: '150px' }} className="greyButton" type="button" onClick={handleQuestionModalClose}>
                                        Cancel
                                    </button>
                                </div>
                                {message &&
                                    <div className="successMessage">
                                        <p>{message}</p>
                                    </div>}

                            </form>
                        </Box>
                    </Modal>

                    {/* Modal for sending questions to user */}
                    <Modal
                        open={openSendQuestionModal}
                        onClose={handleSendQuestionModalClose}
                        aria-labelledby="send-question-modal"
                        aria-describedby="send-question-modal"
                    >
                        <Box sx={modalStyleWide}>
                            <div>
                                <h4>Send question to team member</h4>
                                <h6>Select team members to send the question to from the list below</h6>
                            </div>
                            <form onSubmit={sendQuestionToUser}>
                                <div>
                                    <TextField
                                        label="Your selected question"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        value={selectedQuestion.question || ''}
                                        sx={{ width: '100%', mt: 2 }}
                                        disabled
                                    />

                                </div>

                                <div
                                    className="table-container"
                                    style={{ marginTop: '20px', maxHeight: '40vh' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Select</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredUsers && filteredUsers.map((user) => (
                                                <tr key={user.uid}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedTeamMember.includes(user.uid)}
                                                            onChange={() => handleSendingQuestionChange(user.uid)}
                                                        />
                                                    </td>
                                                    <td>{user.firstName}</td>
                                                    <td>{user.lastName}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                                {/* <FormControl sx={{ width: '100%', mt: 4, textAlign: 'left' }}>
                                    <InputLabel id="teamMembers">Select team members</InputLabel>
                                    <Select
                                        labelId="teamMembers"
                                        id="teamMembers"
                                        multiple
                                        value={selectedTeamMember || []}
                                        onChange={handleSendingQuestionChange}
                                        label="Team Members"
                                        style={{ padding: '8px' }}
                                        error={Boolean(errorQuestion.teamMemberError)}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 58 * 4.5 + 8,
                                                    width: 250,
                                                },
                                            },
                                        }}

                                    >
                                        {filteredUsers && filteredUsers.map((user) => (
                                            <MenuItem key={user.uid} value= {user.uid}>
                                               {user.firstName} {user.lastName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl> */}
                                <div style={{ marginTop: '20px' }}>
                                    <button style={{ minWidth: '150px' }} className="addUserBtn" type="submit" onClick={sendQuestionToUser}>Send Question</button>
                                    <button style={{ minWidth: '150px' }} className="greyButton" type="button" onClick={handleSendQuestionModalClose}>
                                        Cancel
                                    </button>
                                </div>
                                <p>{message}</p>
                            </form>
                        </Box>
                    </Modal>
                </div>
                {/* contacts modal */}
                <div className="admin-modal-container contacts ">
                    <Modal
                        open={openContactsModal}
                        onClose={closeContactModal}
                        aria-labelledby="contact-modal"
                        aria-describedby="contact-modal"
                    >
                        <Box sx={modalStyleWideContacts}>
                            <div>
                                <h4>Profile analysis</h4>
                                <h6>{selectedContact && selectedContact.fullName}</h6>
                            </div>
                            <div style={{ overflow: 'auto', maxHeight: '100%' }}>
                                {/* <FormControl sx={{ mt: 2, textAlign: 'left', width: '100%' }}>
                                    <InputLabel id="Contact">Contact</InputLabel>
                                    <Select
                                        labelId="Contact"
                                        id="Contact"
                                        value={selectedContact || ''}
                                        onChange={(e) => setSelectedContact(e.target.value)}
                                        label="Contact"
                                        renderValue={(value) => {
                                            return value.fullName;
                                        }}
                                    >
                                        {filteredContacts && filteredContacts.map((contact) => (
                                            contact.contacts.map((c) => (
                                                <MenuItem key={c.id} value={c}>
                                                    {c.fullName}
                                                </MenuItem>
                                            ))
                                        ))}
                                    </Select>
                                    </FormControl> */}
                                <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'left' }}>
                                    {selectedContact && selectedContact.analyze && selectedContact.analyze.map((a) =>
                                        <div key={a.questionId} style={{ marginTop: '20px' }}>
                                            <div>
                                                <h6 style={{ margin: '25px 0px 10px' }}>1. Networking Profile</h6>
                                                <div className="question-container">
                                                    <p>Which networking <span >group</span> does this TIKO fit?</p>
                                                    <p>{getOptionLabel(a.answers.networking, questionOneOptions)}</p>
                                                </div>
                                                <div className="question-container">
                                                    <p>Which <span>role</span> does this TIKO fit?</p>
                                                    <p>{getOptionLabel(a.answers.role, questionTwoOptions)}</p>
                                                </div>
                                                <div className="question-container">
                                                    <p>How many years have you known this TIKO?</p>
                                                    <p>{getOptionLabel(a.answers.years, questionThreeOptions)}</p>
                                                </div>
                                                <div className="question-container">
                                                    <p>What is the current status of this TIKO?</p>
                                                    <p>{getOptionLabel(a.answers.status, questionFourOptions)}</p>
                                                </div>
                                                <div className="question-container">
                                                    <p>What will best describe this TIKO?</p>
                                                    <p>{getOptionLabel(a.answers.describe, questionFiveOptions)}</p>
                                                </div>
                                            </div>

                                            <h6 style={{ margin: '25px 0px 0px' }}>2. Past Analysis: evaluate your TIKO's Clarity</h6>
                                            <p style={{ fontSize: '14px', marginTop: '0px', color: 'grey' }}>0 = not relevant, 1 = low, 5 = High</p>
                                            <div className="question-container">
                                                <p>How <span>relevant</span> was the information this TIKO used to provide?</p>
                                                <p>{a.answers.relevantInfo}</p>
                                            </div>
                                            <div className="question-container">
                                                <p> How <span>reliable</span> was the information? </p>
                                                <p>{a.answers.reliableInfo}</p>
                                            </div>
                                            <div className="question-container">
                                                <p>How <span>accurate</span> was the information?</p>
                                                <p>{a.answers.accurateInfo}</p>
                                            </div>
                                            <div className="question-container">
                                                <p>How was the information provided <span>in the needed time</span>?</p>
                                                <p>{a.answers.time}</p>
                                            </div>

                                            <h6 style={{ margin: '25px 0px 0px' }}>3. Knowledge VS. conflict of interests</h6>
                                            <p style={{ fontSize: '16px', marginTop: '5px', color: 'grey' }}>0 = not relevant, 1 = low, 5 = High</p>
                                            <div className="question-container">
                                                <p>Given your question, what is this TIKO level of <span>knowledge and expertise</span></p>
                                                <p>{a.answers.knowledge}</p>
                                            </div>
                                            <div className="question-container">
                                                <p>Given your question, what is this TIKO level of <span>conflict of interest?</span></p>
                                                <p>{a.answers.conflict}</p>
                                            </div>

                                        </div>

                                    )}
                                </div>
                                <div className="bottomNavigation" style={{ marginTop: '30px' }}>
                                    <button
                                        style={{ minWidth: '150px' }}
                                        className="greyButton"
                                        onClick={closeContactModal}>
                                        Close
                                    </button>
                                </div>

                            </div>
                        </Box>
                    </Modal>
                </div>

            </div>
        </div>
    );
}


export default withAuthentication(Admin, true,);

// Add event listeners to track user activity and reset the idle timer
// useEffect(() => {


//     const handleUserActivity = () => {
//         // resetIdleTimer();
//     };
//     document.addEventListener('mousemove', handleUserActivity);
//     document.addEventListener('keydown', handleUserActivity);
//     return () => {
//         document.removeEventListener('mousemove', handleUserActivity);
//         document.removeEventListener('keydown', handleUserActivity);
//     };
// }, []);

// 300 * 1000; // 5 minutes (in milliseconds)
// const idleTimeout = 15 * 60 * 1000; // 15 minutes (in milliseconds)
// let timer;

// Reset the idle timer on user activity
// const resetIdleTimer = () => {
//     clearTimeout(timer);
//     timer = setTimeout(logoutUser, idleTimeout);
// };

// // Logout the user and redirect to the login page
// function logoutUser() {
//     signOut(auth).then(() => {
//         // reset local state:
//         setUserState(prevState => ({
//             ...prevState,
//             user: null,
//             role: '',
//             projects: [],
//             currProjectId: '',
//             contacts: [],
//             contactsLists: [],
//             currTable: [],
//             currListId: '',
//             interviews: [],
//             conclusions: [],
//             maps: [],
//             results: [],
//             cv: [],
//             age: '',
//             firstName: '',
//             lastName: '',
//         }));
//         // reset local storage:
//         navigate("/login")

//     }).catch((error) => {
//         console.log("error signing out: ", error)
//     });
// }